import React, { useEffect, useState } from "react";
import { Alert, Badge, Col, Image, Modal, Row, Tab, Table, Toast } from "react-bootstrap";
import Utils from "../../configs/Utils";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";

const OrderDetailPopup = ({...props}) => {
  const { popupShow, setPopupShow, popupData } = props;

  const [backgroundColor, setBackgroundColor] = useState('#3f3f3f');

  useEffect(() => {
    if (popupData.status) {
      setBackgroundColor(Utils.stringToColor(popupData.status));
    }
  }, [popupData]);
  console.log(popupData);
  return (
    <Modal
      show={popupShow}
      onHide={() => setPopupShow(false)}
      dialogClassName="modal-90w"
      aria-labelledby="order-detail-popup-title"
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title id="order-detail-popup-title">
          <span>Order detail</span>
          <Badge bg="custom" className="ms-3" style={{backgroundColor, position: "relative", top: "-2px"}} text="Light">{popupData.status}</Badge>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="mb-3">
          <Col md={3}>
            <h5 className="mt-3">Created Time</h5>
            <span>{ Utils.dateFormat(popupData.create_time) }</span>
          </Col>
          <Col md={3}>
            <h5 className="mt-3">Fulfillment type</h5>
            <span>{ popupData.fulfillment_type }</span>
          </Col>
          <Col md={3}>
            <h5 className="mt-3">Auto Cancel Time</h5>
            <span>{ Utils.dateFormat(popupData.cancel_order_sla_time, false) }</span>
          </Col>
          <Col md={3}>
            <h5 className="mt-3">Delivery option</h5>
            <span>{ popupData.delivery_option_name }</span>
          </Col>
          <Col md={3}>
            <h5 className="mt-3">Tracking</h5>
            <span>{ popupData.shipping_provider_id } ({ popupData.shipping_provider })</span>
          </Col>
          <Col md={3}>
            <h5 className="mt-3">Shipping due time</h5>
            <span>{ Utils.dateFormat(popupData.shipping_due_time, false) }</span>
          </Col>
        </Row>

        { popupData.buyer_message?.length ? (
          <Row className="mb-3">
            <Col>
              <Alert variant="warning"><strong>Customer message:</strong> { popupData.buyer_message }</Alert>
            </Col>
          </Row>
        ) : <></> }

        { popupData.line_items?.length ? (
          <>
          <OverlayScrollbarsComponent
            style={{ maxWidth: '100%', marginTop: '15px' }}
            options={{ scrollbars: { autoHide: 'scroll' } }}
          >
            <Table striped hover className="text-nowrap">
              <thead>
                <tr>
                  <th width={30}>#</th>
                  <th width={50}>Thumb</th>
                  <th>Name</th>
                  <th className="text-sm-end" width={70}>Price</th>
                </tr>
              </thead>
              <tbody>
              { popupData.line_items.map((item, i) => (
                <tr key={item.id}>
                  <td>{ i+1 }</td>
                  <td ><Image rounded width={100} src={Utils.getMediaImg({ url: item.sku_image, width: 100, height: 100 })} alt={item.product_name} /></td>
                  <td style={{verticalAlign: 'baseline'}} className="text-overflow">
                      <h6><strong>{item.product_name}</strong><br /></h6>
                      <p>
                        <small>
                        <strong>Status</strong>: {item.display_status}<br />
                        <strong>SKU</strong>: {item.seller_sku}<br />
                        <strong>Variation</strong>: {item.sku_name}<br />
                        </small>
                      </p>
                    </td>
                  <td><strong>{item.sale_price} {item.currency}</strong> { parseFloat(item.original_price) > parseFloat(item.sale_price)? (
                    <s>{item.original_price} {item.currency}</s>
                  ) : '' }</td>
                </tr>
              )) }
              </tbody>
            </Table>
          </OverlayScrollbarsComponent>
          </>
        ) : (
          <></>
        ) }

        { Object.keys(popupData).length ? (
          <>
            <Row className="mt-5">
              <Col md={5}>
                <h4>What your buyer paid</h4>
                <Table responsive="sm" striped>
                  <tbody>
                    <tr>
                      <th>Payment method</th>
                      <td className="text-md-end">{ popupData.payment_method_name }</td>
                    </tr>
                    <tr>
                      <th>Original total</th>
                      <td className="text-md-end">{ popupData.payment.original_total_product_price } { popupData.payment.currency }</td>
                    </tr>
                    <tr>
                      <th>Original shipping fee</th>
                      <td className="text-md-end">-{ popupData.payment.original_shipping_fee } { popupData.payment.currency }</td>
                    </tr>
                    <tr>
                      <th>Platform shipping discount</th>
                      <td className="text-md-end">{ popupData.payment.shipping_fee_platform_discount } { popupData.payment.currency }</td>
                    </tr>
                    <tr>
                      <th>Tax</th>
                      <td className="text-md-end">-{ popupData.payment.tax } { popupData.payment.currency }</td>
                    </tr>
                    <tr>
                      <th>Platform discount</th>
                      <td className="text-md-end">{ popupData.payment.platform_discount } { popupData.payment.currency }</td>
                    </tr>
                    <tr>
                      <th>Seller discount</th>
                      <td className="text-md-end">{ popupData.payment.seller_discount } { popupData.payment.currency }</td>
                    </tr>
                    <tr>
                      <th>Total net</th>
                      <td className="text-md-end"><strong>{ popupData.payment.total_amount } { popupData.payment.currency }</strong></td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
              <Col md={7}>
                <h4>Customer Information</h4>
                <Table responsive="sm" striped>
                  <tbody>
                    <tr>
                      <th width={200}>Buyer Email</th>
                      <td className="text-md-end"><div><span className="text-truncate d-block" style={{maxWidth: "300px"}}>{ popupData.buyer_email }</span></div></td>
                    </tr>
                    <tr>
                      <th>Shipping type</th>
                      <td className="text-md-end">{ popupData.shipping_type }</td>
                    </tr>
                    <tr>
                      <th style={{verticalAlign: 'baseline'}}>Shipping Address</th>
                      <td className="text-md-end">
                        <span>
                          {popupData.recipient_address.name}<br />
                          { popupData.recipient_address.phone_number }<br />
                          {popupData.recipient_address.full_address}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </>
        ) : (
          <></>
        ) }
      </Modal.Body>
    </Modal>
  );
}

export default OrderDetailPopup;