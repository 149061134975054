import React from "react";
import { Badge } from "react-bootstrap";
import moment from "moment";
import Config from "./Config";
import Enums from "./Enums";

interface ApiRequestParams {
  path: string;
  pathParams?: Record<string, string>;
  type: string;
  cookie?: { token?: string };
  payload?: string | any | undefined;
  query?: Record<string, any>;
  dataType?: string;
}

interface FetchParams {
  method: string;
  headers: Record<string, any>;
  body: any;
}

interface MediaIMGProps {
  url: String;
  width: Number;
  height: Number;
}

type DateFormatParams = string | number;

type StringToColorParams = string;

type BadgeLabel = "pending" | "suspended" | "failed" | "unconnected" | "processing" | "connected" | string;

const Utils = {
  setPageTitle: (title: string | undefined) => {
    document.title = (title ? `${title} | ${process.env.REACT_APP_TITLE}` : process.env.REACT_APP_TITLE) as string;
  },
  _apiRequest: async (par: ApiRequestParams): Promise<any> => {
    const { path, pathParams, type, cookie, payload, query, dataType = 'application/json' } = par;
    const apiURI = Utils.prebaseApiUrl({ path, pathParams, query });

    const params = {
      method: type,
      headers: {} as Record<string, any>
    } as FetchParams;

    if (dataType === 'application/json') {
      params.headers['Content-Type'] = dataType;
    }

    if (cookie?.token?.includes('Bearer')) {
      params.headers['Authorization'] = cookie.token;
    }
    if (payload && payload !== null) {
      params.body = dataType !== 'application/json' ? payload : JSON.stringify(payload);
    }
    const res = await fetch(apiURI, params);
    const data = await res.json();
    return data;
  },
  prebaseApiUrl: (args: { path: string; pathParams: Record<string, string> | undefined; query?: Record<string, any>; }) => {
    const { path, pathParams, query } = args;
    let apiUrl = Config.getApiURL(path);
    if (pathParams) {
      Object.keys({...pathParams}).forEach((key) => {
        apiUrl = apiUrl.replace('['+key+']', pathParams[key]);
      });
    }

    if (query && Object.keys(query).length > 0) {
      console.log('run', query);
      const queryOb = new URLSearchParams(query);
      apiUrl += '?' + queryOb.toString();
    }

    return apiUrl;
  },
  getPublicImage: (imagePath: string) => {
    return process.env.PUBLIC_URL + imagePath;
  },
  buildBadgeLable: (label: BadgeLabel, className: string = '') => {
    switch(label) {
      case "pending":
        return <Badge bg="warning" text="dark">{label}</Badge>
      case "suspended":
      case "failed":
        return <Badge bg="danger">{label}</Badge>
      case "unconnected":
        return <Badge bg="secondary">{label}</Badge>
      case "processing":
        return <Badge bg="warning" text="dark">{label}</Badge>
      case "connected":
        return <Badge bg="success">{label}</Badge>
      case "V4":
        return <Badge className={className} bg="info">{label}</Badge>
      case "V2":
        return <Badge className={className} bg="warning">{label}</Badge>
      case "V5":
        return <Badge className={className} bg="danger">{label}</Badge>
      case "UNPAID":
          return <Badge className={className} bg="custom" style={{backgroundColor: '#f0f0f0'}} text="dark">{label}</Badge>
      case "AWAITING_SHIPMENT":
        return <Badge className={className} bg="custom" style={{backgroundColor: '#fff3cd'}} text="dark">{label}</Badge>
      case "CANCELLED":
        return <Badge className={className} bg="custom" style={{backgroundColor: '#dc3545'}}>{label}</Badge>
      case "IN_TRANSIT":
        return <Badge className={className} bg="custom" style={{backgroundColor: '#004085'}}>{label}</Badge>
      case "DELIVERED":
        return <Badge className={className} bg="custom" style={{backgroundColor: '#28a745'}}>{label}</Badge>
      case "ON_HOLD":
        return <Badge className={className} bg="custom" style={{backgroundColor: '#ffc107'}} text="dark">{label}</Badge>
      default:
        return <Badge bg="success">{label}</Badge>
    }
  },
  dateFormat: (dateStr: DateFormatParams, fromNow: boolean = true) => {
    if (typeof dateStr === 'number' && dateStr.toString().length < 11) {
      dateStr = dateStr * 1000;
    }
    const date = new Date(dateStr);
    const dateMM = moment(date);
    const diffFromNow = moment(Date.now()).diff(dateMM, 'hours');
    if (fromNow && diffFromNow < 24) {
      return dateMM.fromNow();
    } else {
      return dateMM.format('MMM DD, YYYY - hh:mm A');
    }
  },
  mdRand: (min: number, max: number): number => {
    return Math.round(Math.random() * (max - min) + min);
  },
  stringToColor: (text: StringToColorParams): string => {
    let hash = 0;
    for (let i = 0; i < text.length; i++) {
      hash = text.charCodeAt(i) + ((hash << 5) - hash); // Generate a unique hash
    }
    let color = '#';
    // let rgb = [];
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xFF; // Convert hash into RGB components
      const darkValue = Math.floor((value / 255) * 128); // Scale to 0-127
      color += ('00' + darkValue.toString(16)).slice(-2); // Use slice instead of substr
    }
    // // Calculate brightness using the perceived brightness formula
    // const brightness = (0.299 * rgb[0] + 0.587 * rgb[1] + 0.114 * rgb[2]);
    // const isDark = brightness < 128; // Dark if brightness is less than 128

    return color;
  },
  getMediaImg: (query: MediaIMGProps) => {
    let imageUrl = Config.getApiURL(Enums.endpoints.media.IMG as string);
    
    if (query && Object.keys(query).length > 0) {
      const queryOb = new URLSearchParams(query as Record<string, any>);
      imageUrl += '?' + queryOb.toString();
    }
    return imageUrl;
  }
}

export default Utils;