import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";

import { faPen, faReceipt, faTrashCan, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Form, Placeholder, Row, Table } from "react-bootstrap";

import Utils from "../../configs/Utils";
import Enums from "../../configs/Enums";
import StoreListItem from "./StoreListItem";
import { Store } from "../../Models/Store";
import { useAuth } from "../../contexts/AuthContext";
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import PopupModal from "../Common/Popup";

interface StoreListProps {
  page: number;
  setCount: React.Dispatch<React.SetStateAction<number>>;
  limit: number;
  // loading: boolean;
  // setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const StoreList: React.FC<StoreListProps> = ({...props}) => {
  const {
    page,
    setCount,
    limit
  } = props;

  const [cookie] = useCookies([]);
  const [storeList, setStoreList] = useState<Store[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { currentUser } = useAuth();
  const [popupShow, setPopupShow] = useState<boolean>(false);
  const [crrStore, setCrrStore] = useState<Store | null>(null);
  const [flashsaleAction, setFlashsaleAction] = useState<string>('submit');
  const [flashsaleFormData, setFlashsaleFormData] = useState<any>({
    discount: '10',
    expired_in: '24'
  });
  const [flashSaleSubmit, setFlashSaleSubmit] = useState<boolean>(false);

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFlashsaleFormData((prev: any) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    const fetchStores = async () => {
      try {
        const response: {
          data: Store[],
          count: number
        } = await Utils._apiRequest({
          path: Enums.endpoints.store.ALL as string,
          type: 'GET',
          query: { page, limit },
          cookie
        });
        setLoading(false);
        setStoreList(response.data);
        setCount(response.count);
      } catch(error) {
        console.log((error as Error).message);
      }
    }

    setLoading(true);
    fetchStores();
  }, [cookie, page]);

  const submitFlashsaleHandle = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFlashSaleSubmit(true);
    setPopupShow(false);
    setFlashsaleAction('submit');
  }

  const deleteFlashSale = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setFlashSaleSubmit(true);
    setPopupShow(false);
    setFlashsaleAction('delete');
  }

  return (
    <>
    <OverlayScrollbarsComponent
      style={{ maxWidth: '100%' }}
      options={{ scrollbars: { autoHide: 'scroll' } }}
    >
      <Table striped hover className="table-text-inline">
        {/* <caption className="pt-2 pb-0">List of Stores</caption> */}
        <thead>
          <tr>
            <th style={{ width: 20 }}>#</th>
            <th>Store Name</th>
            <th style={{ width: 100 }}>Status</th>
            <th style={{ width: 70 }}>Solds</th>
            <th style={{ width: 110 }}>Flatform</th>
            <th>Domain / Cipher</th>
            { currentUser?.admin ? <th>Seller</th> : <></> }
            <th style={{ width: 70 }}></th>
          </tr>
        </thead>
        <tbody>
          { loading ? (
            <>
            { [...Array(limit).keys()].map((i) => {
              return (
                <tr key={ i } className="placeholder-group">
                  <td>{ i+1 }</td>
                  <td>
                    <Placeholder animation="glow">
                      <Placeholder xs={ 10 } />
                    </Placeholder>
                  </td>
                  <td>
                    <Placeholder animation="glow">
                      <Placeholder xl={ 8 } bg="success" />
                    </Placeholder>
                  </td>
                  <td>
                    <Placeholder animation="glow">
                      <Placeholder xl={ 10 } bg="success" />
                    </Placeholder>
                  </td>
                  <td>
                    <Placeholder animation="glow">
                      <Placeholder xs={ 10 } />
                    </Placeholder>
                  </td>
                  <td>
                    <Placeholder animation="glow">
                      <Placeholder xs={ Utils.mdRand(4, 8) } />
                    </Placeholder>
                  </td>
                  { currentUser?.admin ? (
                    <td>
                      <Placeholder animation="glow">
                        <Placeholder xs={ Utils.mdRand(4, 8) } />
                      </Placeholder>
                    </td>
                  ) : <></> }
                  <td className="text-inline text-end">
                    <Button className="ms-1" size="sm" disabled variant="warning" title="Edit"><FontAwesomeIcon icon={ faPen } /></Button>
                    <Button className="ms-1" size="sm" disabled variant="secondary" title="Edit"><FontAwesomeIcon icon={ faPen } /></Button>
                    <Button className="ms-1" size="sm" disabled variant="danger" title="Edit"><FontAwesomeIcon icon={ faTrashCan } /></Button>
                  </td>
                </tr>
              )
            }) }
            </>
          ) : (
            <>
            { storeList.length > 0 ? (
              storeList.map((store, i) => {
                return (
                  <StoreListItem 
                    key={i}
                    index={i}
                    store={store} 
                    storeList={storeList} 
                    setStoreList={setStoreList}
                    setPopupShow={setPopupShow}
                    crrStore={crrStore}
                    setCrrStore={setCrrStore}
                    flashSaleSubmit={flashSaleSubmit}
                    setFlashSaleSubmit={setFlashSaleSubmit}
                    flashsaleFormData={flashsaleFormData}
                    flashsaleAction={flashsaleAction}
                  />
                )
              })
            ): (
              <tr>
                <td colSpan={ 8 }><i>Nothing to show...</i></td>
              </tr>
            )}
            </>
          ) }
        </tbody>
      </Table>
    </OverlayScrollbarsComponent>

    <PopupModal title="Create Flash Sale" popupShow={popupShow} setPopupShow={setPopupShow}>
      <Form onSubmit={submitFlashsaleHandle}>
        <p>You are creating flash sale for: <strong>{ crrStore?.storeFriendlyName }</strong></p>
        <Row>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="flashsale-discount" onChange={handleFormChange}>
              <Form.Label>Discount (%)</Form.Label>
              <Form.Control type="number" name="discount" min={5} max={50} step={1} defaultValue={10} />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-3" controlId="flashsale-expired">
              <Form.Label>Expired in (h)</Form.Label>
              <Form.Select name="expired_in" disabled aria-label="Flashsale expired in" onChange={handleFormChange}>
                <option value="24">24H</option>
                <option value="48">48H</option>
                <option value="72">72H</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col className="text-end" md={12}>
            <Button variant="link" className="me-1 text-danger" onClick={deleteFlashSale}>Delete</Button>
            <Button variant="primary" type="submit"><FontAwesomeIcon icon={faReceipt} /> Create</Button>
          </Col>
        </Row>
      </Form>
    </PopupModal>
    </>  
  );
}

export default StoreList;